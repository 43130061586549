import React from 'react';
import {withRouter} from 'react-router-dom';


function MainNavbarHeader() {
    return (
        <div>
            <img
                src="assets/images/backgrounds/header-logo.png"
                alt="react"
                width="190"
                style={{marginLeft: 10, marginTop: 10}}
            />
        </div>
    );
};

export default withRouter(MainNavbarHeader);
