import {FuseLoadable} from '@fuse';

export const OrderConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : '/apps/orders/:orderId/:orderHandle?',
            component: FuseLoadable({
                loader: () => import('./Order')
            })
        },
        {
            path     : '/apps/orders',
            component: FuseLoadable({
                loader: () => import('./Orders')
            })
        },
        
    ]
};
