import {FuseLoadable} from '@fuse';

export const ProductConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : '/apps/products/:productId/:productHandle?',
            component: FuseLoadable({
                loader: () => import('./Product')
            })
        },
        {
            path     : '/apps/products',
            component: FuseLoadable({
                loader: () => import('./Products')
            })
        },
        
    ]
};
