import { FuseLoadable } from '@fuse';

export const BankConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: '/apps/banks/:bankId/:bankHandle?',
      component: FuseLoadable({
        loader: () => import('./Bank')
      })
    },
    {
      path: '/apps/banks',
      component: FuseLoadable({
        loader: () => import('./Banks')
      })
    },

  ]
};
