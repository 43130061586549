import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import classNames from 'classnames';
import {CircularProgress, Avatar, Button, Icon, IconButton, ListItemIcon, ListItemText, Popover, MenuItem, Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import * as quickPanelActions from 'main/quickPanel/store/actions';
import * as authActions from 'auth/store/actions';
import {bindActionCreators} from 'redux';
import {FuseAnimate} from '@fuse';
import {Link} from 'react-router-dom';
import withReducer from 'store/withReducer';
import reducer from './quickPanel/store/reducers';

const styles = theme => ({
    root     : {
        display   : 'flex',
        alignItems: 'center',
        width     : '100%'
    },
    seperator: {
        width          : 1,
        height         : 64,
        backgroundColor: theme.palette.divider
    },
    notificationBadge: {
        position: 'relative',
        right: '5px',
        top: '-15px',
        color: 'red',
        margin: '0 -.8em',
        padding: '5px 10px',
    }
});

class MainToolbar extends Component {
    state = {
        userMenu: null
    };

    userMenuClick = event => {
        this.setState({userMenu: event.currentTarget});
    };

    userMenuClose = () => {
        this.setState({userMenu: null});
    };

    loadNotifications = () => {
        const { toggleQuickPanel, getQuickPanelData } = this.props;
        let userId = localStorage.getItem('user_id');
        getQuickPanelData(userId);
        toggleQuickPanel(true);
    }

    render()
    {
        const {classes, user, logout, isLoadingRequest, count} = this.props;
        const {userMenu} = this.state;

        return (
            <div className={classNames(classes.root, "flex flex-row")}>
                
            <div className="flex flex-1 justify-center">
                {isLoadingRequest && <CircularProgress color="secondary"/>}
            </div>
            
                <div className="flex">
                    <FuseAnimate delay={300}>
                        <Button className="h-64" onClick={this.userMenuClick}>
                            {user.data.photoURL ?
                                (
                                    <Avatar className="" alt="user photo" src={user.data.photoURL}/>
                                )
                                :
                                (
                                    <Avatar className="">
                                        {user.data.person.displayName[0]}
                                    </Avatar>
                                )
                            }

                            <div className="hidden md:flex flex-col ml-12 items-start">
                                <Typography component="span" className="normal-case font-600 flex">
                                    {user.data.person.displayName}
                                </Typography>
                            </div>

                            <Icon className="text-16 ml-12 hidden sm:flex" variant="action">keyboard_arrow_down</Icon>
                        </Button>
                    </FuseAnimate>

                    <Popover
                        open={Boolean(userMenu)}
                        anchorEl={userMenu}
                        onClose={this.userMenuClose}
                        anchorOrigin={{
                            vertical  : 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical  : 'top',
                            horizontal: 'center'
                        }}
                        classes={{
                            paper: "py-8"
                        }}
                    >
                            <React.Fragment>
                                <MenuItem component={Link} to="/pages/profile" onClick={this.userMenuClose}>
                                    <ListItemIcon>
                                        <Icon>account_circle</Icon>
                                    </ListItemIcon>
                                    <ListItemText className="pl-0" primary="Mi Perfil"/>
                                </MenuItem>
                                
                                <MenuItem component={Link} to="/pages/changePassword" onClick={this.userMenuClose}>
                                    <ListItemIcon>
                                        <Icon>vpn_key</Icon>
                                    </ListItemIcon>
                                    <ListItemText className="pl-0" primary="Cambiar contraseña"/>
                                </MenuItem>
                                <MenuItem component={Link} to="/pages/params" onClick={this.userMenuClose}>
                                    <ListItemIcon>
                                        <Icon>settings</Icon>
                                    </ListItemIcon>
                                    <ListItemText className="pl-0" primary="Configuración"/>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        logout();
                                        this.userMenuClose();
                                    }}
                                    component={Link} to="/logout" >
                                    <ListItemIcon>
                                        <Icon>exit_to_app</Icon>
                                    </ListItemIcon>
                                    <ListItemText className="pl-0" primary="Cerrar sesión"/>
                                </MenuItem>
                            </React.Fragment>
                    </Popover>

                    <div className={classes.separator}/>

                    <IconButton className="w-64 h-64" onClick={() => this.loadNotifications()}>
                        <Icon>notifications</Icon>
                        {count && count > 0 && (
                            <small className={classes.notificationBadge}>{count}</small>
                        )}
                    </IconButton>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        toggleQuickPanel: quickPanelActions.toggleQuickPanel,
        getQuickPanelData: quickPanelActions.getQuickPanelData,
        logout          : authActions.logoutUser,
        submitLogin     : authActions.submitLogin
    }, dispatch);
}


function mapStateToProps({auth, quickPanel, fuse})
{
    return {
        user: auth.user,
        count: quickPanel.quickPanel.count,
        isLoadingRequest: fuse.request.loading > 0
    }
}

export default withReducer('quickPanel', reducer)(withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(MainToolbar)));
