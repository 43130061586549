import {authRoles} from 'auth';
import store from 'store';
import {logoutUser} from 'auth/store/actions';
import { deleteTokenDeviceApi } from '../../../api';

export const LogoutConfig = {
    auth  : authRoles.user,
    routes: [
        {
            path     : '/logout',
            component: () => {
                const body = {
                    UserId: localStorage.user_id,
                    tokenDevice: localStorage.tokenDevice
                }
                deleteTokenDeviceApi(body)
                .then(console.log)
                .catch(console.error)
                store.dispatch(logoutUser());
                localStorage.clear();
                return 'Logging out..'
            }
        }
    ]
};

