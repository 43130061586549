import {FuseLoadable} from '@fuse';

export const ClientConfig = {
    settings: {
        layout: {}
    },
    routes  : [
        {
            path     : '/apps/clients/:clientId/:clientHandle?',
            component: FuseLoadable({
                loader: () => import('./Client')
            })
        },
        {
            path     : '/apps/clients',
            component: FuseLoadable({
                loader: () => import('./Clients')
            })
        },
        
    ]
};
