import {combineReducers} from 'redux';
import email from './forgot.reducer';


const reducer = combineReducers({
   
    email
});

export default reducer;
