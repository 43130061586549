import {combineReducers} from 'redux';
//import cards from './cards.reducer';
import user from './reset.reducer';
 

const reducer = combineReducers({
   
    user,
    
    
});

export default reducer;
