/**
 * Authorization Roles
 */
const authRoles = {
    admin    : ['admin'],
    affiliate: ['admin', 'affiliate'],
    client  : ['admin','client'],
    pharmacy: ['admin', 'affiliate', 'pharmacy'],
    staff    : ['admin', 'staff'],
    user     : ['admin', 'affiliate', 'client'],
    onlyGuest: ['guest']
};

export default authRoles;