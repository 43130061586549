import React, {Component} from 'react';
import {Divider, Drawer, List, ListItem, ListItemText, ListSubheader, Typography} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles/index';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as Actions from './store/actions/index'
import {FuseScrollbars} from '@fuse';
import moment from 'moment';
import withReducer from 'store/withReducer';
import reducer from './store/reducers';

const styles = theme => ({
    root: {
        width: 280
    }
});

class QuickPanel extends Component {
    state = {
        checked: ['notifications']
    };

    handleToggle = value => () => {
        const {checked} = this.state;
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if ( currentIndex === -1 )
        {
            newChecked.push(value);
        }
        else
        {
            newChecked.splice(currentIndex, 1);
        }

        this.setState({
            checked: newChecked
        });
    };

    render()
    {
        const {classes, state, toggleQuickPanel, cleanNoficationsCount, data} = this.props;
        return (
            <Drawer
                classes={{paper: classes.root}}
                open={state}
                anchor="right"
                onClose={() => {
                    toggleQuickPanel(false)
                    cleanNoficationsCount()
                }}
            >
                <FuseScrollbars>

                    <ListSubheader component="div">Hoy</ListSubheader>

                    <div className="mb-0 py-16 px-24">
                        <Typography className="mb-12 text-32" color="textSecondary">
                            {moment().format('dddd')}
                        </Typography>
                        <div className="flex">
                            <Typography className="leading-none text-32" color="textSecondary">{moment().format('DD')}</Typography>
                            <Typography className="leading-none text-16" color="textSecondary">th</Typography>
                            <Typography className="leading-none text-32" color="textSecondary">{moment().format('MMMM')}</Typography>
                        </div>
                    </div>
                    <Divider/>
                    <List>
                        <ListSubheader component="div">Notificaciones</ListSubheader>
                        {data && data.events.map(event => (
                            <ListItem key={event.id}>
                                <ListItemText
                                    primary={event.text}
                                    secondary={event.description}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <Divider/>
                    <List>
                        <ListSubheader component="div">Notes</ListSubheader>
                        {data && data.notes.map(note => (
                            <ListItem key={note.id}>
                                <ListItemText
                                    primary={note.title}
                                    secondary={note.detail}
                                />
                            </ListItem>
                        ))}
                    </List>
                    <Divider/>
                </FuseScrollbars>
            </Drawer>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        toggleQuickPanel : Actions.toggleQuickPanel,
        getQuickPanelData: Actions.getQuickPanelData,
        cleanNoficationsCount: Actions.cleanNoficationsCount,
    }, dispatch);
}

function mapStateToProps({quickPanel})
{
    return {
        state: quickPanel.quickPanel.state,
        data : quickPanel.quickPanel.data
    }
}

export default withReducer('quickPanel', reducer)(withStyles(styles, {withTheme: true})(connect(mapStateToProps, mapDispatchToProps)(QuickPanel)));
