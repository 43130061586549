import { FuseLoadable } from '@fuse';

export const PharmacyConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: '/apps/pharmacies/:pharmacyId/:pharmacyHandle?',
      component: FuseLoadable({
        loader: () => import('./Pharmacy')
      })
    },
    {
      path: '/apps/pharmacies',
      component: FuseLoadable({
        loader: () => import('./Pharmacies')
      })
    },

  ]
};
