import { FuseLoadable } from '@fuse';

export const QuestionConfig = {
  settings: {
    layout: {}
  },
  routes: [
    {
      path: '/apps/questions',
      component: FuseLoadable({
        loader: () => import('./Questions')
      })
    },

  ]
};
