import {showMessage, fetch_start, fetch_end} from 'store/actions/fuse';
import { getNewNotificationsApi, checkNewNotificationsApi} from '../../../../api';
export const TOGGLE_QUICK_PANEL = '[QUICK PANEL] TOGGLE QUICK PANEL';
export const NOTIFICATIONS_COUNT = 'NOTIFICATIONS COUNT';
export const GET_QUICK_PANEL_DATA = '[QUICK PANEL] GET DATA';

export function getQuickPanelData(user_id)
{   
    return (dispatch) => {
        dispatch(fetch_start())
        getNewNotificationsApi(user_id).then(response => {
            if(response.status){
                dispatch({
                    type: GET_QUICK_PANEL_DATA,
                    payload: {events: response.data, notes: []}
                })
            }
        }
        ,err => {
            console.log(err)
            dispatch(showMessage({message: 'Error de conexión', variant:"error"}))
        }).finally(() => dispatch(fetch_end()))
    }
}

/**
 * Request every minute if there are new notifications and update counter
 * @param {number} userId 
 */
export function checkUnreadNotifications(userId) {
    return (dispatch) => {
        window.interval = setInterval(() => {
            checkNewNotificationsApi(userId).then(response => {
                if (response.status) {
                    const count = response.data.length;
                    count > 0 ? dispatch(getNotificationsCount(count)) : dispatch(getNotificationsCount(null));
                } else {
                    dispatch(getNotificationsCount(null));
                }
            });
        }, 60000)
    }
}

/**
 * clean the notifications counter without waiting for make the request to the server
 */
export function cleanNoficationsCount() {
    return {
        type    : NOTIFICATIONS_COUNT,
        payload : null
    }
};

export function getNotificationsCount(count) {
    return {
        type    : NOTIFICATIONS_COUNT,
        payload : count
    }
}

export function toggleQuickPanel(value)
{
    return {
        type   : TOGGLE_QUICK_PANEL,
        payload: value
    }
}