import React from 'react';
import {Redirect} from 'react-router-dom';
import {FuseUtils} from '@fuse/index';
import {ClientConfig} from 'main/content/clients/ClientConfig';
import {ProductConfig} from 'main/content/products/ProductConfig';
import {OrderConfig} from 'main/content/orders/OrderConfig';
import {PharmacyConfig} from 'main/content/pharmacies/PharmacyConfig';
import {BankConfig} from 'main/content/banks/BankConfig';
import {Error404PageConfig} from 'main/content/pageError/404/Error404PageConfig';
import {LoginConfig} from 'main/content/login/LoginConfig';
import {ForgotConfig} from 'main/content/forgotPassword/ForgotConfig';
import {ResetConfig} from 'main/content/resetPassword/ResetConfig';
import {LogoutConfig} from 'main/content/logout/LogoutConfig';
import {ProfilePageConfig} from 'main/content/profile/ProfilePageConfig';
import {HomePageConfig} from 'main/content/home/HomePageConfig';
import {QuestionConfig} from 'main/content/questions/QuestionConfig';
import _ from '@lodash';
import {authRoles} from 'auth';

function setAdminAuth(configs)
{
    return configs.map(config => _.merge({}, config, {auth: authRoles.admin}))
}

function setUserAuth(configs)
{
    return configs.map(config => _.merge({}, config, {auth: authRoles.user}))
}

function setPharmacyAuth(configs)
{
    return configs.map(config => _.merge({}, config, {auth: authRoles.pharmacy}))
}

const routeConfigs = [
    ...setAdminAuth([
        BankConfig,
        PharmacyConfig,
        QuestionConfig
    ]),
    ...setUserAuth([
        ProductConfig,
        ClientConfig
    ]),
    ...setPharmacyAuth([
        HomePageConfig,
        ProfilePageConfig,
        OrderConfig
    ]),
    Error404PageConfig,
    LogoutConfig,
    LoginConfig,
    ForgotConfig,
    ResetConfig,
];

export const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {   path: '/',
        exact: true,
        component: () => <Redirect to="/logout"/>
    },
    {
        path:'*',
        component: () => <Redirect to="/pages/errors/error-404"/>
    }
];
