import { apiFetch } from './apiFetch'

const URL_BACKEND = process.env.REACT_APP_API_URL_BACKEND || 'http://localhost:8080';
export const API_URL_SOCKET_BACKEND = process.env.REACT_APP_API_URL_SOCKET_BACKEND || 'http://localhost:8081';
export const API_URL_BACKEND = `${URL_BACKEND}/api/`

export const getPassword = (email) => apiFetch(`user/forgot/password`,{method:'POST',body:email})
export const getConfirmToken= (userId, token) => apiFetch(`user/valid/token/${userId}/${token}`);
export const changeApiPassword = (id, pass) => apiFetch(`user/${id}`, {method: 'PUT', body: pass});
export const resetApiPassword= (id, pass) => apiFetch(`user/reset/password`,{method:'PUT',body:{userId: id, newPassword: pass.password}});
export const resetApiPasswordAdmin = (id) => apiFetch(`user/reset/unsend/password`, {method:'PUT', body:{clientId: id}});

export const editUserApi = (user,userId, person) => apiFetch(`${person}/${userId}`,{method: 'PUT',body:user})

export const loginApi =({email,password}) => apiFetch(`login`,{headers : {
    'Content-Type': 'application/json',
    'Authorization': 'Basic '+btoa(`${email}:${password}`),
  }})

//Get all for combo
export const getTypeDocsAllApi = () => apiFetch(`typeDoc/all`)
export const getStatesApi = () => apiFetch(`state`)
export const getMunicipalitiesForState = (stateId) => apiFetch(`state/municipality/${stateId}`)
export const getParishesForMunicipality = (municipalityId) => apiFetch(`municipality/parish/${municipalityId}`)

export const getClientApi = (id) => apiFetch(`client/${id}`);
export const getPagedClientsApi = (start,end, filter, order) => apiFetch(`client?range=[${start},${end}]&filter=${JSON.stringify(filter)}&order=${order}`)
export const getClientByDOCApi = (doc) => apiFetch(`client/doc/search?docNumber=${doc}`);
export const saveClientApi = (data) => apiFetch(`client`, {method: 'POST', body: data, headers: {}});
export const editClientApi = (data, clientId) => apiFetch(`client/${clientId}`, {method: 'PUT', body: data});
export const deleteClientApi = (clientId) => apiFetch(`client/${clientId}`, {method: 'DELETE'});
export const importClientsApi = (data) => apiFetch(`client/multiple`, {method: 'POST', body: data, headers: {}});

export const getMenuUserApi = (userId) => apiFetch(`user/menu/${userId}`);
export const getNotificationsUserApi = (UserId) => apiFetch(`notification/${UserId}`);
export const getNewNotificationsApi = (UserId) => apiFetch(`notification/unread/${UserId}`);
export const checkNewNotificationsApi = (UserId) => apiFetch(`notification/check/${UserId}`);

export const deleteTokenDeviceApi = (body) => apiFetch(`notification/device`, {method: 'DELETE', body})
export const saveTokenDeviceApi = (body) => apiFetch(`notification/device`, {method: 'POST', body})

export const getProductApi = (id) => apiFetch(`product/${id}`);
export const getPagedProductsApi = (start,end, filter, order) => apiFetch(`product?range=[${start},${end}]&filter=${JSON.stringify(filter)}&order=${order}`)
export const saveProductApi = (data) => apiFetch(`product`, {method: 'POST', body: data});
export const importProductsApi = (data) => apiFetch(`product/multiple`, {method: 'POST', body: data, headers: {}});
export const editProductApi = (data, id) => apiFetch(`product/${id}`, {method: 'PUT', body: data});
export const unpublishProductsApi = () => apiFetch(`product/unpublish`, {method: 'PUT'});
export const deleteProductApi = (id) => apiFetch(`product/${id}`, {method: 'DELETE'});

export const saveImageApi = (data, productId) => apiFetch(`image/${productId}`, {method: 'POST', body: data, headers: {}});
export const deleteImageApi = (imageId) => apiFetch(`image/${imageId}`, {method: 'DELETE'});

export const getPagedOrdersApi = (start,end, filter, order) => apiFetch(`order?range=[${start},${end}]&filter=${JSON.stringify(filter)}&order=${order}`)
export const getOrderApi = (id) => apiFetch(`order/${id}`);
export const saveOrderApi = (data) => apiFetch(`order`, {method: 'POST', body: data});
export const editOrderApi = (data, id) => apiFetch(`order/${id}`, {method: 'PUT', body: data});
export const deleteOrderApi = (id) => apiFetch(`order/${id}`, {method: 'DELETE'});
export const reserveOrderApi = (id, body) => apiFetch(`order/reserve/${id}`, {method: 'PUT', body});
export const deliveryOrderApi = (id) => apiFetch(`order/delivery/${id}`, {method: 'PUT'});
export const finishOrderApi = (id) => apiFetch(`order/finish/${id}`, {method: 'PUT'});
export const rejectOrderApi = (id, body) => apiFetch(`order/reject/${id}`, {method: 'PUT', body});

export const getPharmaciesApi = (start, end) => apiFetch(`pharmacy?range=[${start},${end}]`);
export const getPharmacyApi = (id) => apiFetch(`pharmacy/${id}`);
export const getAllPharmaciesApi = (id) => apiFetch(`pharmacy/all`);
export const savePharmacyApi = (data) => apiFetch(`user/affiliate`, {method: 'POST', body: data});
export const editPharmacyApi = (data, id) => apiFetch(`pharmacy/${id}`, {method: 'PUT', body: data});
export const deletePharmacyApi = (id) => apiFetch(`pharmacy/${id}`, {method: 'DELETE'});

export const getBanksApi = (start, end) => apiFetch(`bank?range=[${start},${end}]`);
export const getBankApi = (id) => apiFetch(`bank/${id}`);
export const saveBankApi = (data) => apiFetch(`bank/`, {method: 'POST', body: data});
export const editBankApi = (data, id) => apiFetch(`bank/${id}`, {method: 'PUT', body: data});
export const deleteBankApi = (id) => apiFetch(`bank/${id}`, {method: 'DELETE'});

export const getParamsApi = () => apiFetch(`param`);
export const updateParamsApi = data => apiFetch('param', { method: 'PUT', body: data });

export const getQuestionsApi = (start, end) => apiFetch(`question?range=[${start}],${end}`);
export const getPagedQuestionsApi = (start, end, filter) => apiFetch(`question?range=[${start},${end}]&filter=${JSON.stringify(filter)}`)
export const getQuestionApi = (id) => apiFetch(`question/${id}`);
export const answerQuestionApi = (data, id) => apiFetch(`question/answer/${id}`, {method: 'PUT', body: data});
export const updateQuestionApi = (data, id) => apiFetch(`question/${id}`, {method: 'PUT', body: data});
export const deleteQuestionApi = (id) => apiFetch(`question/${id}`, {method: 'DELETE'});

export const getCodeApi = code => apiFetch(`code/verify/${code}`);